<template>
  <div class="user-info">
    <div class="user-info-self">
      <div
        class="user-info-base"
        style="height:340px"
      >
        <div class="user-info-content">
          <template v-if="isSelf">
            <div class="user-info-c-head">
              <el-avatar
                style="width: 160px;height:160px;"
                :src="userBaseInfo.avatar"
              ></el-avatar>
            </div>
            <p class="name">{{userBaseInfo.realname}}</p>
          </template>
          <template v-else>
            <div class="user-info-c-head">
              <el-avatar
                style="width: 160px;height:160px;"
                :src="userInfo.avatar"
              ></el-avatar>
            </div>
            <p class="name">{{userInfo.realname}}</p>
          </template>
          <div class="shopping-desc">
            <ul>
              <li>
                <img
                  class="shopping-icon"
                  :src="integralIcon"
                />
                <span>积分：</span>
                <label>{{userInfo.integral}}</label>
                <el-button
                  style="margin-left:10px"
                  @click="showExchange"
                  type="primary"
                  size="mini"
                  plain
                >积分兑换E币</el-button>
              </li>
              <li>
                <img
                  class="shopping-icon"
                  :src="moneyIcon"
                />
                <span>E币：</span>
                <label>{{userInfo.currency}}</label>
                <el-button
                  style="margin-left:10px"
                  @click="toPay"
                  type="primary"
                  size="mini"
                  plain
                >E币充值</el-button>
              </li>
            </ul>
            <div class="tip">积分获得方法：上课获得奖励、公开发布作品、作品被点赞或被收藏</div>
          </div>
        </div>
      </div>
    </div>
    <div class="user-info-body"  :style="mainStyle">
      <template>
        <div class="user-info-program-item">
          <el-row>
            <el-col :span="2">
              <div style="width:10px;height:10px"></div>
            </el-col>
            <el-col :span="4">
              <el-menu
                :default-active="currentMenu"
                class="my-menu2"
                @select="handleSelect"
                text-color="#409eff"
                active-text-color="#ffff"
              >
                <el-menu-item index="GiftList">
                  <span slot="title">礼物商城</span>
                </el-menu-item>
                <el-menu-item index="Integral">
                  <span slot="title">积分明细</span>
                </el-menu-item>
                <el-menu-item index="Currency">
                  <span slot="title">E币明细</span>
                </el-menu-item>
                <el-menu-item index="Address">
                  <span slot="title">地址管理</span>
                </el-menu-item>
              </el-menu>
            </el-col>
            <el-col :span="16">
              <!-- <gift-list></gift-list> -->
              <component :is="currentMenu"></component>
            </el-col>
            <el-col :span="2">
              <div style="width:10px;height:10px"></div>
            </el-col>
          </el-row>
        </div>
      </template>
    </div>
    <el-dialog
    class="integrate-dialog"
    title="提示"
      :visible.sync="dialogVisible"
      width="500px"
      center
      :destroy-on-close="true"
      :before-close="handleClose"
    >
      <div class="dialog-content">
        <div>
          您有
          <span class="red">{{userInfo.integral}}</span>
          积分可以兑换
          <span class="red">{{userInfo.integral/rule}}</span>
          E币
        </div>
        <div>
            请输入兑换的数量
            <el-input
              size="mini"
              v-model="num"
            ></el-input>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          :disabled="userInfo.integral>0?false:true"
          @click="exchange"
        >兑换</el-button>
      </span>
    </el-dialog>
    <el-dialog class="pay-dialog" title="E币充值" :visible.sync="payDialogVisible" width="700px" center>
      <Pay :currency="userInfo.currency" />
    </el-dialog>
  </div>
</template>
<script>
import GiftList from '@/components/shopping/GiftList.vue';
import Integral from '@/components/shopping/Integral.vue';
import Currency from '@/components/shopping/Currency.vue';
import Address from '@/components/shopping/Address.vue';
import Pay from './Pay.vue'

import integralIcon from '@/assets/image/integral-icon.png';
import moneyIcon from '@/assets/image/money-icon.png';
import { getUserInfo, getUserCurrency, integralExchange, getExchangeRule } from "@/api/user"
import Vue from 'vue'
import { getUserInfo as getUserBaseInfo } from '@/utils/auth';
export default {
  name: 'ShoppingCenter',
  components: {
    GiftList,
    Integral,
    Currency,
    Address
    ,
    Pay
  },
  data() {
    return {
      currentMenu: 'GiftList',
      num: 0,
      integralIcon,
      moneyIcon,
      dialogVisible: false,
      isSelf: !this.$route.query.uid || this.$route.query.uid == Vue.ls.get('Login_Userid'),
      uid: this.$route.query.uid,
      userInfo: {
        name: '',
        avatar: '',
        integral: 0,
        currency: 0
      },
      rule: 100 //积分兑换E币
      ,
      payDialogVisible:false
    };
  },
  watch: {
    $route(newRoute) {
      this.uid = newRoute.query.uid,
        this.init();
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    userBaseInfo: function () {
      return getUserBaseInfo();
    },
    mainStyle() {
      return this.$route.meta.hideFooter ? { minHeight: ((window.screen.height - 501) + 'px') } : { minHeight: ((window.screen.height - 782) + 'px') }
    }
  },
  methods: {
    init() {
      if (!this.uid && !Vue.ls.get('Login_Userid')) {
        this.$router.push(`/login?redirect_uri=${location.href}`)
        // window.location.href = window.location.origin+"/passport/dologin?url="+encodeURI(location.href)
      } else {
        this.getUserInfo();
        this.getRules()
      }
    },
    getRules() {
      getExchangeRule().then(res => {
        if (res.success) {
          this.rule = res.result.integralEcurrency
        }
      })
    },
    getUserInfo() {
      getUserInfo({ uid: this.uid || Vue.ls.get('Login_Userid') }).then(res => {
        if (res.success) {
          this.userInfo = res.result;

          getUserCurrency().then(res => {
            if (res.success) {
              this.$set(this.userInfo, 'currency', res.result.currency)
              this.$set(this.userInfo, 'integral', res.result.integral)
              // this.userInfo.currency = res.result.currency
              // this.userInfo.integral = res.result.integral
            }
          })
        }
      })
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleSelect(index, indexPath) {
      this.currentMenu = index
    },
    toPay() {
      // this.$router.push("/pay")
      this.payDialogVisible=true
    },
    showExchange() {
      if (this.userInfo.integral) this.dialogVisible = true
      else this.$message.warning('您没有可以兑换的积分')
    },
    exchange() {
      if (this.num) {
        this.dialogVisible = false
        integralExchange({ studentId: this.userBaseInfo.id, integral: this.num, integralEcurrency: this.rule }).then(res => {
          if (res.success) {
            this.$message.success('积分兑换成功！')
            location.reload()
          }
          else {
            this.$message.error('积分兑换失败！')
          }
        })
      }
      else {
        this.$message.warning('请输入需要兑换的积分')
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/views/user-info.scss';
.user-info-program-item {
  margin-top: 30px;
}
.my-menu-block {
  position: fixed;
  z-index: 99;
}
.my-menu-title {
  text-align: center;
  background-color: #e8e8e8;
  width: 140px;
  line-height: 32px;
  height: 32px;
  font-size: 15px;
}
.my-menu {
  width: 140px;
  text-align: center;
  line-height: 20px;
  border: 1px solid #e8e8e8;
  padding: 5px 0;
  li {
    line-height: 32px;
    height: 32px;
    font-size: 15px;
  }
}
.el-menu {
  border: 0;
  .el-menu-item {
    border-radius: 3px;
    line-height: 40px;
    height: 40px;
    margin-bottom: 10px;
    box-shadow: 0px 1px 6px #aed6fd;
  }
  .is-active {
    background-color: #aed6fd;
  }
}
.my-menu2 {
  text-align: center;
  width: 100px;
}
.dialog-content {
  margin-bottom: 10px;
  line-height: 34px;
  .red {
    color: #ff0000;
  }
}
.user-info .el-form-item__error {
  white-space: initial;
}
.shopping-desc {
  color: white;
  font-size: 14px;
  margin-top: 20px;
  ul {
    display: flex;
    justify-content: space-between;
    width: 530px;
    margin: auto;
    position: relative;
  }
  span {
    padding-left: 24px;
  }
  .shopping-icon {
    width: 14px;
    position: absolute;
    top: 7px;
  }
  .tip {
    text-align: center;
    color: #e8e8e8;
    font-size: 14px;
    margin: 8px;
  }
  .link {
    color: #fff;
    background-color: #f59a23;
    font-size: 13px;
    text-align: center;
    display: inline-block;
    width: 100px;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    position: relative;
    z-index: 999;
    margin: 0 15px;
  }
}
</style>
<style lang="scss">
.user-info{
  .integrate-dialog{
    .el-dialog{
      height: 300px;
      border-radius: 10px;
      overflow: hidden;
      .el-dialog__header{
        height: 50px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url(~@/assets/image/title-banner.jpg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        .el-dialog__title{
          font-size: 18px;
          color: #fff;
          font-weight: bold;
        }
        .el-dialog__headerbtn{
          top: 16px;
          width: 16px;
          height: 16px;
          background: #fff;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          .el-dialog__close{
            font-size: 14px;
            font-weight:bold;
            color: #ccc;
          }
        }
      }
      .el-dialog__body{
        padding: 40px 0;
        .dialog-content{
          display: flex;
          justify-content: center;
          flex-flow: row wrap;
          font-size: 20px;
            color: #333;
            margin: 0;
          div:first-child{
            margin-bottom: 24px;
            span{
              color: #e11f1f;
            }
          }
          div:last-child{
            display: flex;
            align-items: center;
            .el-input{
              width: 135px;
              height: 30px;
              margin: 0;
              margin-left: 14px;
              .el-input__inner{
              border-color: #333;
              }
            }
          }
        }
      }
      .el-dialog__footer{
        padding: 0;
        span{
          display: block;
          .el-button{
            width: 100px;
            height: 40px;
          }
          .el-button--default{
            border-color: #333;
            color: #9e9e9e;
            &:hover{
              background-color: #faf9f9;
            }
          }
          .el-button--primary{
            background-color: #5C9DF8;
             &:hover{
              background-color: #96c2ff;
            }
          }
        }
      }
    }
  }

}
</style>

<style lang="scss">
.user-info{
  .pay-dialog{
    .el-dialog{
      height: 600px;
      border-radius: 10px;
      overflow: hidden;
      .el-dialog__header{
        padding: 0;
        height: 70px;
        display: flex;
        justify-content: center;align-items: center;
        background-image: url(~@/assets/image/title-banner.jpg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        .el-dialog__title{
          color: #fff;
          font-weight: bold;
          font-size: 20px;
        }
        .el-dialog__headerbtn{
          top: 20px;
          width: 16px;
          height: 16px;
          background: #fff;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          .el-dialog__close{
            font-size: 14px;
            font-weight:bold;
            color: #ccc;
          }
        }
      }
      .el-dialog__body{
        height: 530px;
        padding: 30px 50px 14px;
        .shopping-pay{
          width: 100%;
          height: 100%;
          .shopping-currency{
            height: 125px;
            border-radius: 10px;
             background-image: url(~@/assets/image/余额底图.jpg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        margin-bottom: 15px;
        color: #fff;
        font-weight: bold;
        position: relative;
        .currency-desc{
          font-size: 24px;
          position: absolute;
          top: 20px;
          left: 60px;
          line-height: 20px;
        }
        .currency-num{
          font-size: 30px;
          position: absolute;
          top:  73px;
          left: 60px;
          line-height: 20px;

        }
          }
          .pay-title{
            line-height: 20px;
            font-size: 14px;
            color: #999999;
            margin-bottom: 18px;
          }
          .pay-item{
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            .el-button{
              width: 140px;
              height: 54px;
              background-color: #F6F7F9;
              border-radius: 10px;
              color: #333;
              border: none;
              font-size: 14px;
              font-weight: 500;
              margin: 0;
              margin-bottom: 15px;
            }
            .el-button--primary{
              background-color: #BFE2FF;
            }
            .el-input-number{
              width: 140px;
              height: 54px;
              .el-input{
                width: 100%;
                height: 100%;

                .el-input__inner{
                  width: 100%;
                  height: 100%;
              background-color: #F6F7F9;
  border: none;
  border-radius: 10px;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  &::placeholder{
    color: #999999;
  }
                }
              }
            }
          }
          .pw-title{
            margin-bottom: 16px;
          }
          .pay-ways{
            width: 100%;
            height: 58px;
            display: flex;
            justify-content: space-between;
            .el-radio{
              width: 293px;
              height: 100%;
              margin: 0;
              border: 1px solid #7F7F7F;
              border-radius: 10px;
              .el-radio__input{
                display: none;
              }
              .el-radio__label{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                padding-left: 10px;
                .el-image{
                  height: 100% ;
                  margin-right: 22px;
                  .pw-name{
                    color: #333333;
                  }
                }
              }
            }
            .el-radio.is-checked{
                border:1px solid #2CB62C;
                background-color: #EEFFEE;
                .pw-name{
                    color: #333333;
                  }
            }
          }
          .pay-ok-button{
            width: 200px;
            height: 60px;
            background-color: #BFE2FF;
            border-radius: 10px;
            border: none;
            font-size: 16px;
            color: #fff;
            font-weight: bold;
            margin-top: 15px !important;
            position: relative;
            left: 200px;
            &:hover{
              background-color: #5C9DF8;
            }
          }
        }
      }
    }
  }
}
</style>