<template>
  <div style="min-height:299px">
    <el-button
      @click="add"
      size="mini"
      type="primary"
    >新增地址</el-button>
    <span class="addr-tips">最多可以创建5个地址</span>
    <el-table :data="dataList">
      <el-table-column
        fixed
        property="name"
        label="收货人"
      ></el-table-column>
      <el-table-column
        property="phone"
        label="电话"
      ></el-table-column>
      <el-table-column
        property="city"
        label="所在城市"
      ></el-table-column>
      <el-table-column
        property="area"
        label="所在区"
      ></el-table-column>
      <el-table-column
        property="detailedAddress"
        label="详细地址"
      ></el-table-column>
      <el-table-column
        property="defaultAddress"
        label="设置为默认"
        align="center"
      >
        <template slot-scope="{row}">
          <el-radio
            v-model="defaultAddr"
            :label="row.id"
            @change="handleChangeAddr(row.id)"
          ><span></span></el-radio>
        </template>
      </el-table-column>
      <el-table-column
        label=""
        align="center"
      >
        <template slot-scope="{row}">
          <!-- <el-button size="mini"  @click="edit(row)">编辑</el-button> -->
          <el-link
            type="primary"
            @click="edit(row)"
          >编辑</el-link>
          <el-divider direction="vertical"></el-divider>
          <!-- <el-button size="mini"  type="danger" @click="del(row.id)" class="link-left">删除</el-button> -->
          <el-link @click="del(row.id)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>

    <AddressEdit
      :dialogVisible.sync="addressDialogVisible"
      :address="editAddress"
      @refreshList="getData"
    ></AddressEdit>

    <!-- <AddressSelect :dialogVisible.sync="addressSelectDialogVisible" @chooseAddress="chooseAddress"></AddressSelect> -->
  </div>
</template>
<script>
import { getUserInfo, getAddressList, delAddress, enableDefault } from "@/api/user"

import AddressEdit from '@/components/shopping/AddressEdit'
import AddressSelect from '@/components/shopping/AddressSelect'

export default {
  name: 'Address',
  components: {
    AddressEdit,
    AddressSelect
  },
  data() {
    return {
      dataList: [],
      defaultAddr: '',
      addressDialogVisible: false,
      editAddress: {},
      addressSelectDialogVisible: false
    }
  },
  computed: {
    userInfo: function () {
      return getUserInfo();
    },
  },
  // watch:{
  //   addressDialogVisible(val){
  //     if(!val) this.getData()
  //   }
  // },
  created() {
    this.getData()
  },
  methods: {
    chooseAddress(addr) {
      // console.log(addr)
    },
    getData() {
      getAddressList().then(res => {
        if (res.success) {
          this.dataList = res.result
          let da = this.dataList.filter(item => item.defaultAddress)
          if (da && da.length > 0) this.defaultAddr = da[0].id
        }
      })
    },
    handleCurrentChange(val) {
      this.getData();
    },
    add() {
      if (this.dataList.length == 5) return this.$message.warning('最多只能添加5个地址信息哦')
      this.editAddress = {}
      this.addressDialogVisible = true
      //this.addressSelectDialogVisible = true
    },
    edit(row) {
      this.editAddress = row
      this.addressDialogVisible = true
    },
    del(id) {
      delAddress(id).then(res => {
        if (res.success) {
          this.$message.success('删除成功')
          this.getData()
        }
        else {
          this.$message.error(res.message)
        }
      })
    },
    handleChangeAddr(id) {
      this.defaultAddr = id
      enableDefault({ id: id }).then(res => { })
    }
  }
}
</script>
<style lang="scss" scoped>
.link {
  color: #fff;
  background-color: #f59a23;
  font-size: 13px;
  text-align: center;
  display: inline-block;
  width: 100px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  position: relative;
  z-index: 999;
}
.link:hover {
  opacity: 0.85;
  color: #ffffff !important;
}
.addr-tips {
  color: #aaaaaa;
  position: relative;
  left: 8px;
  font-size: 12px;
}
</style>