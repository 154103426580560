<template>
<div>
    <el-table :data="dataList">
        <el-table-column fixed property="createTime" label="操作时间"></el-table-column>
        <el-table-column property="event" label="积分记录"></el-table-column>
        <el-table-column property="integral" label="积分变更"></el-table-column>
        <el-table-column property="residualIntegral" label="积分剩余"></el-table-column>
    </el-table>
    <div class="content-pagination">
    <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="searchParams.pageNo"
        :page-size="searchParams.pageSize"
        layout="total, prev, pager, next,jumper"
        :total="dataTotal">
    </el-pagination>
    </div>
</div>
</template>
<script>
import {  getUserInfo, getIntegralList} from "@/api/user"

export default {
  name: 'Integral',
  components: {
  },
  data() {
    return {
        dataList:[],
        dataTotal:0,
        searchParams:{
            pageSize:10,
            pageNo:1
        },
    }
  },
  computed: {
    userInfo: function() {
      return getUserInfo();
    },
  },
  created(){
    this.getData()
  },
  methods:{
    getData(){
      getIntegralList(this.searchParams).then(res => {
        this.dataList = res.result.records
        this.dataTotal = res.result.total
      })
    },
    handleCurrentChange(val){
      this.searchParams.pageNo = val;
      this.getData();
    },
  }
}
</script>
<style lang="scss" scoped>

.content-pagination{
  margin-top: 20px;
  margin-bottom: 50px;
  justify-content: right;
  text-align: right;
}
</style>