<template>
<div>
    <div class="search">
    <div class="search-child">
        <el-input size="small" 
        @clear="getData" 
        clearable v-model="searchParams.name" 
        placeholder="请输入商品名称" 
        @keyup.enter.native="getData(true)"
        style="width: 200px; margin-right: 10px;"/>
        <el-button size="small" type="primary" @click="getData(true)">确定</el-button>
    </div>
        <div class="clear"></div>
    </div>
    <div class="content">
        <el-card class="item-goods" v-for="(item,index) in dataList" :key="index" :body-style="{ padding: '0px' }" shadow="never">
        <el-image @click="toDetail(item.id)"
         :src="item.cover" class="image" fit="fill"></el-image>
        <div style="padding: 0 7px;">
            <div class="goods-name" @click="toDetail(item.id)">
                 <el-tooltip v-if="item.name.length > 23"
                      class="item" 
                      effect="dark" 
                      :content="item.name" placement="top-start">
                     <span>{{item.name}}</span>
                 </el-tooltip>
                 <span v-else>{{item.name}}</span>
            </div>
            <div class="bottom clearfix">
                <img class="icon" :src="integralIcon" />
                <span>&nbsp;&nbsp;{{item.ecurrency}}&nbsp;&nbsp;E币</span>
            </div>
        </div>
        </el-card>
    </div>
    <div class="content-pagination">
    <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="searchParams.pageNo"
        :page-size="searchParams.pageSize"
        layout="total, prev, pager, next,jumper"
        :total="dataTotal">
    </el-pagination>
    </div>
</div>
</template>
<script>
import integralIcon from '@/assets/image/integral-dark-icon.png';
import { getGoodsList } from "@/api/user"

export default {
  name: 'GiftList',
  components: {
  },
  data() {
    return {
        integralIcon,
        dataList:[],
        dataTotal:0,
        searchParams:{
            pageSize:8,
            pageNo:1
        },
    }
  },
  created(){
      this.getData();
  },
  methods:{
    getData(){
      getGoodsList(this.searchParams).then(res => {
          if(res.success){
              this.dataList = res.result.records
              this.dataTotal = res.result.total
              for(let i in this.dataList){
                  let covers = this.dataList[i].cover.split(',');
                  this.dataList[i].cover = covers[0];
              }
          }else{
              this.$message.error(res.message);
          }
      })
    },
    handleCurrentChange(val){
      this.searchParams.pageNo = val;
      this.getData();
    },
    toDetail(id){
        this.$router.push({path:'/giftDetail',query:{id:id}});
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/views/gift-list.scss';
</style>